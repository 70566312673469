import { createApp } from "vue";
import App from "./App.vue";

import { createPinia } from "pinia";
import PersistedState from "pinia-plugin-persistedstate";

import Antd from "ant-design-vue";
import "./styles/index.less";

import router from "./router";

import lazyPlugin from 'vue3-lazy'

import { VueDapp } from "vue3-dapp-boot";
import { ethers } from "ethers";
(window as any).ethers = ethers;

import PerfectScrollbar from 'vue3-perfect-scrollbar'
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'

import infiniteScroll from 'vue3-infinite-scroll-good'

const app = createApp(App)
app.use(Antd);
app.use(createPinia().use(PersistedState));
app.use(lazyPlugin,{
  loading: '/assets/default.png',
  error: '/assets/error.png'
});
app.use(router);
app.use(VueDapp);
app.use(PerfectScrollbar)
app.use(infiniteScroll)
app.mount("#app");

//路由跳转后，页面回到顶部
router.afterEach(() => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
});
