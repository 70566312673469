
import { defineComponent,ref } from "vue";
// import { Modal } from 'ant-design-vue';
import HeaderLayout from "@/layouts/module/Header.vue";
import ContentLayout from "@/layouts/module/Content.vue";
import FooterLayout from "@/layouts/module/Footer.vue";

import { storeToRefs } from "pinia";
import { useConnectors } from "@/wallet/hooks/useConnectors";
import { useTransactionStore } from "@/wallet/store/transaction";
import TransactionFail from "@/wallet/components/TransactionFail.vue";
import TransactionSuccess from "@/wallet/components/TransactionSuccess.vue";
import TransactionSubmitted from "@/wallet/components/TransactionSubmitted.vue";
import TransactionConfirmWallet from "@/wallet/components/TransactionConfirmWallet.vue";
import TransactionRejectWallet from "@/wallet/components/TransactionRejectWallet.vue";

export default defineComponent({
  components: {
    HeaderLayout,
    ContentLayout,
    FooterLayout,

    TransactionFail,
    TransactionSuccess,
    TransactionSubmitted,
    TransactionConfirmWallet,
    TransactionRejectWallet,
  },
  setup() {
    const { connectors } = useConnectors();

    const transactionStore = useTransactionStore();
    const {
      visibleTransactionSubmitted,
      visibleConfirmInWallet,
      visibleRejectInWallet,
      visibleSuccess,
      visibleFail,
      description,
      broadcastResult,
    } = storeToRefs(transactionStore);
    //wang
    const visible = ref<boolean>(false);

    const showModal = () => {
      visible.value = true;
    };

    const handleOk = (e: MouseEvent) => {
      console.log(e);
      visible.value = false;
    };

    return {
      connectors,

      visible,
      showModal,
      handleOk,

      visibleTransactionSubmitted,
      visibleConfirmInWallet,
      visibleRejectInWallet,
      visibleSuccess,
      visibleFail,
      description,
      broadcastResult,
    };
  },
});
