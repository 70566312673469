import {
  MetaMaskConnector,
  WalletConnectConnector,
  useWallet,
  useEthers,
  ChainId,
} from "vue3-dapp-boot";
import WalletConnect from "@walletconnect/client";
import { CHAIN_ID } from "../types/ICommon";
import QRCodeModal from "@walletconnect/qrcode-modal";

const win = window as any;

export function useConnectors() {
  // console.log("useConnectors")
  const { connectWithWallet } = useWallet();

  // const metaMaskConnector = new MetaMaskConnector({
  //   appUrl: window.location.href,
  // });
  const walletConnectConnector = new WalletConnectConnector({
    qrcode: true,
    bridge: "https://payments.playmonstergalaxy.com/websocket/",

    // infuraId: "2485de6bf0744ad4bdd1f3f9d26225eb",
    rpc: {
      // [ChainId.Mainnet]: `https://mainnet.infura.io/v3/2485de6bf0744ad4bdd1f3f9d26225eb`,
      [ChainId.Mainnet]: `https://database1.gaiaopen.network`,
      [CHAIN_ID]: `10024`,
    },
    qrcodeModalOptions:{
      registryUrl:"https://registry.walletconnect.com/api/v2/wallets"
    }

  });
  // walletConnectConnector.options.qrcodeModalOptions?.registryUrl="https://registry.walletconnect.com/api/v1/wallets"

  const connectors = [walletConnectConnector];

  const start = async () => {
    if (win.ethereum) {
      console.log('begin meta ...');
      const provider = await walletConnectConnector.getProvider();
      if (provider.connected) {
        await connectWithWallet(walletConnectConnector);
      }
    } else {
      // Create a connector
      // Check if connection is already established
      const connector = new WalletConnect({
        // bridge: "https://bridge.walletconnect.org", // Required
        bridge: "https://payments.playmonstergalaxy.com/websocket/", // Required
        
        // qrcodeModal: QRCodeModal,
      });
      //wang
      connector.chainId = CHAIN_ID
      connector.clientMeta?.icons.unshift("https://moga.gaiaopen.com/favicon.png")
      // const walletConnectProvider = await walletConnectConnector.getProvider();
      // walletConnectProvider.chainId=10024
      // walletConnectProvider.clientMeta?.icons.unshift("https://moga.gaiaopen.com/favicon.png")

      if (connector.connected) {
        const walletConnectProvider =
          await walletConnectConnector.getProvider();
          //wang
          walletConnectProvider.chainId=10024
        if (walletConnectProvider.connected) {
          connectWithWallet(walletConnectConnector);
        }
      }
    }
  };

  window.onload = () => {
    start();
  };

  return {
    connectors,
    reconnect: start,
    // eth_signTypedData,
  };
}
