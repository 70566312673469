
import { defineComponent, ref, watch } from "vue";
import { getPlacement, getHash, getTimestamp, getDeadline } from "@/utils";
import { queryNFTs } from "@/api";

import { CaretDownFilled } from "@ant-design/icons-vue";

import { apiBridge } from "@/api/bridge";

import {
  CHAIN_LIST,
  NFT_Type,
  ASSETS_LIST,
  Symbol_Type,
  DEFAULT_IMG,
  MANAGER,
  GET_CROSSCHAIN_ERC721_TRANSFER_BODY,
  ERC721_CROSSCHAIN_TRANSFER
} from "@/constants";

import { useEthers } from "vue3-dapp-boot";
import { accountStore } from "@/store";
import { storeToRefs } from "pinia";
import WalletConnect from "@walletconnect/client";
import { message } from "ant-design-vue";
import { Modal } from 'ant-design-vue';
import {h } from 'vue';

export default defineComponent({
  name: "Cross-chain Send Drawer",
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  components: {
    CaretDownFilled
  },
  setup(
    props: any,
    context: { emit: (arg0: string, arg1: string) => void }
  ) {

    const token = ref(props.item.id.split("/")[0])

    const level1Value = ref(
      props.item.asset.symbol === Symbol_Type.MOGA
        ? NFT_Type.MOGA
        : NFT_Type.EQPT
    );
    const nftSize = ref(0)

    const level2Value = ref(props.item.itemId);
    const itemImageUrl = ref(props.item.logoUrl);

    const list = ref();
    const loading = ref(false);
    let logoUrlMapping = new Map();

    const getList = () => {
      loading.value = true;
      queryNFTs(level1Value.value === NFT_Type.MOGA ? 0 : 1).then((res) => {
        list.value = res.data.nfts;
        list.value.forEach((element) => {
          element.metadatas.forEach((data) => {
            if (data.key == "logo_url") {
              logoUrlMapping.set(element.itemId, data.value);
            }
          });
        });
        nftSize.value = res.data.nfts.length
        loading.value = false;
      });
    };
    getList();

    const getItemUrl = () => {
      itemImageUrl.value = logoUrlMapping.get(level2Value.value);
    };

    const addressValue = ref("")

    const placement = ref<string>(String(getPlacement()));

    const store = accountStore();
    const { getAccountId } = storeToRefs(store);

    const { address } = useEthers();
    const deadline = getDeadline();

    const handleSendClick = () => {

      if (addressValue.value == undefined) {
        // isErrorMessage.value = "address is not null"
        // isError.value = true
        return
      }

      const data = ref(GET_CROSSCHAIN_ERC721_TRANSFER_BODY);
      data.value.id = getTimestamp();
      const params = [
        {
          sender: address.value,
          receiver: addressValue.value,
          fromId: Number(getAccountId.value),
          toId: Number(0),
          tokenId: String(level2Value.value),
          deadline: deadline,
          manager: MANAGER,
          nft: token,
          disChainId: Number(process.env.VUE_APP_CHAINID),
        },
      ];
      data.value.params = params;
      apiBridge(data.value).then((res) => {
        if(res?.error){
          message.error(res.error.message);
          return
        }
        //wang
      Modal.info({
        title: 'This is a notification message',
        content: h('div', {}, [
          h('p', 'Please confirm the operation on the wallet...'),
        ]),
        onOk() {
          console.log('ok');
        },
      });
        eth_signTypedData(res);
      });
    };

    const eth_signTypedData = async (typedData: any) => {
      const connector = new WalletConnect({
        bridge: "https://bridge.walletconnect.org", // Required
      });
      const signTypedData = {
        jsonrpc: typedData.jsonrpc,
        id: typedData.id,
        types: typedData.result.types,
        primaryType: typedData.result.primaryType,
        domain: typedData.result.domain,
        message: typedData.result.message,
      };
      const msgParams = [address.value, JSON.stringify(signTypedData)];
      connector
        .signTypedData(msgParams)
        .then((signature) => {
          const data = ref(ERC721_CROSSCHAIN_TRANSFER);
          data.value.id = getTimestamp();

          const ethSigUtil = require("@metamask/eth-sig-util");
          const hash = ethSigUtil.TypedDataUtils.eip712Hash(
            signTypedData,
            ethSigUtil.SignTypedDataVersion.V4
          );

          const params = [
            {
              sender: address.value,
              receiver: addressValue.value,
              fromId: Number(getAccountId.value),
              toId: Number(0),
              tokenId: String(level2Value.value),
              deadline: deadline,
              manager: MANAGER,
              nft: token,
              disChainId: Number(process.env.VUE_APP_CHAINID),
              hash: "0x" + hash.toString("hex"),
              signature: String(signature),
            },
          ];
          data.value.params = params;
          apiBridge(data.value).then((res) => {
            // wang
            console.log(res) 
            if(res?.result){
              context.emit("eventRefresh", "");
            }else{
              message.error(res?.error?.message)
            }
          });
        })
        .catch((error) => {
          console.error(error);
        });
    };

    watch(props, ({ item }) => {
      level1Value.value =
        item.asset.symbol === Symbol_Type.MOGA ? NFT_Type.MOGA : NFT_Type.EQPT;
      level2Value.value = item.itemId;
      itemImageUrl.value = item.logoUrl;
      addressValue.value = "";
      getList();
    });

    return {
      ASSETS_LIST,
      level1Value,
      level2Value,
      list,
      nftSize,
      placement,
      addressValue,
      CHAIN_LIST,
      loading,
      itemImageUrl,
      DEFAULT_IMG,
      getItemUrl,
      handleSendClick,
    };
  },
});
