
import { defineComponent } from "vue";
import { LoadingOutlined } from "@ant-design/icons-vue";

export default defineComponent({
  name: "LoadingTransaction",
  components: {
    LoadingOutlined,
  },
  props: {
    description: {
      type: String,
      required: true,
    },
  },
  setup(props) {},
});
