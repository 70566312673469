import { defineStore } from "pinia";
import { ref } from "vue";
import { IOptionUser } from "@/constants";

export const accountStore = defineStore("account", {
  state: () => {
    return {
      isLogin: false,
      isConnected: false,
      isActivated: false, 
      chainId: 0,
      accountId: "",
      address: "",
      showAddress: "",
      balance: 0,
      contacts: ref([] as Array<IOptionUser> ),
    };
  },
  getters: {
    getAccountId(state) {
      return state.accountId
    },
    getAddress(state) {
      return state.address
    },
    getContacts(state) {
      return state.contacts
    }
  },
  actions: {},
  persist: true,
});