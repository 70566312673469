import { ref, reactive, Ref } from "vue";

export function useToggleWalletAccount() {
  const visibleWalletAccount = ref(false);

  const showWalletAccount = () => {
    visibleWalletAccount.value = true;
  };

  const hideWalletAccount = () => {
    visibleWalletAccount.value = false;
  };

  return {
    visibleWalletAccount,
    showWalletAccount,
    hideWalletAccount,
  };
}
