import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex justify-between" }
const _hoisted_2 = { class: "flex justify-center space-x-4" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "flex flex-col items-center justify-center space-y-1 mt-1" }
const _hoisted_5 = { class: "black-title break-words w-48" }
const _hoisted_6 = { class: "flex space-x-4" }
const _hoisted_7 = { class: "flex items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createBlock(_component_a_card, {
    loading: _ctx.loading,
    class: "address-card"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", {
            class: "bg-transparent mt-1",
            innerHTML: _ctx.avatar?.outerHTML.replaceAll('32', '25')
          }, null, 8, _hoisted_3),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.address), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("span", {
              class: "copy px-2 py-1 rounded border cursor-pointer",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.copy(_ctx.address)))
            }, "Copy")
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["loading"]))
}