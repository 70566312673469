
import { defineComponent, ref } from "vue";
import { getPlacement, balanceFormat, getHash, getTimestamp, getDeadline } from "@/utils";
import { queryBalance } from "@/api";

import { CaretDownFilled } from "@ant-design/icons-vue";

import { apiBridge } from "@/api/bridge";

import {
  TOKEN,
  MANAGER,
  GET_CROSSCHAIN_ERC20_TRANSFER_BODY,
  ERC20_CROSSCHAIN_TRANSFER
} from "@/constants";

import { useEthers } from "vue3-dapp-boot";
import { accountStore } from "@/store";
import { storeToRefs } from "pinia";
import { ethers } from "ethers";
import WalletConnect from "@walletconnect/client";
import { message } from 'ant-design-vue';

export default defineComponent({
  name: "Cross-chain Send Drawer",
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    CaretDownFilled
  },
  setup(
    props: unknown,
    context: { emit: (arg0: string, arg1: string) => void }
  ) {
    const value = ref<string>("");
    const value1 = ref<string>("");
    const checked = ref(false);
    const chainValue = ref("")
    const addressValue = ref("")

    const placement = ref<string>(String(getPlacement()));

    const store = accountStore();
    const { getAccountId } = storeToRefs(store);

    const { address } = useEthers();
    const deadline = getDeadline();

    const handleSendClick = () => {

      if (addressValue.value == undefined) {
        // isErrorMessage.value = "address is not null"
        // isError.value = true
        return
      }

      if (amountValue.value == undefined) {
        // isErrorMessage.value = "accountId is not null"
        // isError.value = true
        return
      }
       //wang
       if (amountValue.value > balanceFormat(
        balanceInfo.value?.account?.assets[0]?.amount
      )) {
        // isErrorMessage.value = "Amount out of range"
        // isError.value = true
        return
      }

      const data = ref(GET_CROSSCHAIN_ERC20_TRANSFER_BODY);
      data.value.id = getTimestamp();
      const params = [
        {
          sender: address.value,
          receiver: addressValue.value,
          fromId: Number(getAccountId.value),
          toId: Number(0),
          amount: String(ethers.utils.parseEther(String(amountValue.value))),
          deadline: deadline,
          manager: MANAGER,
          token: TOKEN,
          disChainId: 97
        },
      ];
      data.value.params = params;
      console.log(data.value)
      apiBridge(data.value).then((res) => {
        //wang
        if(res.error){
          message.error(res.error.message);
          return
        }
        eth_signTypedData(res);
      });
    };

    const eth_signTypedData = async (typedData: any) => {
      const connector = new WalletConnect({
        bridge: "https://bridge.walletconnect.org", // Required
      });
      const signTypedData = {
        jsonrpc: typedData.jsonrpc,
        id: typedData.id,
        types: typedData.result.types,
        primaryType: typedData.result.primaryType,
        domain: typedData.result.domain,
        message: typedData.result.message,
      };
      const msgParams = [address.value, JSON.stringify(signTypedData)];
      connector
        .signTypedData(msgParams)
        .then((signature) => {
          const data = ref(ERC20_CROSSCHAIN_TRANSFER);
          data.value.id = getTimestamp();

          const hash = getHash(signTypedData);

          const params = [
            {
              sender: address.value,
              receiver: addressValue.value,
              fromId: Number(getAccountId.value),
              toId: Number(0),
              amount: String(ethers.utils.parseEther(String(amountValue.value))),
              deadline: deadline,
              manager: MANAGER,
              token: TOKEN,
              disChainId: 97,
              hash: hash,
              signature: String(signature),
            },
          ];
          data.value.params = params;
          apiBridge(data.value).then((res) => {
            if(res?.result){
              context.emit("eventRefresh", "");
            }else{
              message.error(res?.error?.message)
            }
          });
        })
        .catch((error) => {
          console.error(error);
        });
    };

    const loading = ref(false);
    const amountValue = ref();
    const balanceInfo = ref();

    queryBalance().then((res) => {
      balanceInfo.value = res.data;
      loading.value = false;
    });

    const handleMaxClick = async () => {
      amountValue.value = balanceFormat(
        balanceInfo.value?.account?.assets[0]?.amount
      );
    };

    return {
      value,
      value1,
      checked,
      placement,
      chainValue,
      addressValue,
      // CHAIN_LIST,
      amountValue,
      loading,
      balanceInfo,
      handleSendClick,
      handleMaxClick,
      balanceFormat,
    };
  },
});
