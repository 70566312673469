
import { defineComponent, ref } from "vue";
import { accountStore } from "@/store";
import { storeToRefs } from "pinia";

import { NFT_Type, Assets_Type } from "@/constants";
import {
  useToggleNFTDetailsDrawer,
  useToggleSendNFTDrawer,
  useToggleSendTokenDrawer,
  useToggleCrossChainNFTDrawer,
  useToggleCrossChainTokenDrawer,
  useToggleGgmSendTokenDrawer,
  useToggleGgmCrossChainTokenDrawer
} from "@/hooks/useToggleWithdraw";

import GGM from "@/components/card/ggm.vue";
import MogaNFT from "@/components/card/nft.vue";

import NFTDetailsDrawer from "@/components/drawer/nft.vue";
import SendNFTDrawer from "@/components/drawer/sendNFT.vue";
import SendTokenDrawer from "@/components/drawer/sendToken.vue";
//wang
import GgmSendTokenDrawer from "@/components/drawer/GgmSendToken.vue";
import GgmCrossChainTokenDrawer from "@/components/drawer/GgmCrossChainToken.vue";
import CrossChainNFTDrawer from "@/components/drawer/crossChainNFT.vue";
import CrossChainTokenDrawer from "@/components/drawer/crossChainToken.vue";

export default defineComponent({
  components: {
    GGM,
    MogaNFT,
    NFTDetailsDrawer,
    SendNFTDrawer,
    SendTokenDrawer,
    CrossChainNFTDrawer,
    CrossChainTokenDrawer,
    GgmSendTokenDrawer,
    GgmCrossChainTokenDrawer
  },
  setup() {
    const {
      visibleNFTDetailsDrawer,
      showNFTDetailsDrawer,
      hideNFTDetailsDrawer,
    } = useToggleNFTDetailsDrawer();

    const { visibleSendNFTDrawer, showSendNFTDrawer, hideSendNFTDrawer } =
      useToggleSendNFTDrawer();

    const { visibleSendTokenDrawer, showSendTokenDrawer, hideSendTokenDrawer } =
      useToggleSendTokenDrawer();

    //wang
    let childClick = (e: any): void => {
      if(e==0){
        showSendTokenDrawer()
      }else if(e==1){
        showGgmSendTokenDrawer()
      }else if(e==2){
        showCrossChainTokenDrawer()
      }else if(e==3){
        showGgmCrossChainTokenDrawer()
      }
      console.log("from son：", e);
    };

    const {
      visibleGgmCrossChainTokenDrawer,
      showGgmCrossChainTokenDrawer,
      hideGgmCrossChainTokenDrawer,
    } = useToggleGgmCrossChainTokenDrawer();
    const {
      visibleGgmSendTokenDrawer,
      showGgmSendTokenDrawer,
      hideGgmSendTokenDrawer,
    } = useToggleGgmSendTokenDrawer();

    const {
      visibleCrossChainNFTDrawer,
      showCrossChainNFTDrawer,
      hideCrossChainNFTDrawer,
    } = useToggleCrossChainNFTDrawer();

    const {
      visibleCrossChainTokenDrawer,
      showCrossChainTokenDrawer,
      hideCrossChainTokenDrawer,
    } = useToggleCrossChainTokenDrawer();

    const sendType = ref(Assets_Type.NFT);
    const assetType = ref(0);

    const item = ref();
    const openNFTDetailsDrawer = async (res: any) => {
      item.value = res;
      sendType.value = Assets_Type.NFT;
      showNFTDetailsDrawer();
    };

    const address = ref("");
    const accountId = ref("");
    const user = ref();

    const closeSendToDrawerAndOpenSendDrawer = async (res: any) => {
      user.value = res;
      if (res.type == Assets_Type.NFT) {
        showSendNFTDrawer();
      } else {
        showSendTokenDrawer();
      }
    };

    const closeNFTDetailsAndOpenSendNFTDrawer = async () => {
      hideNFTDetailsDrawer();
      showSendNFTDrawer();
    };

    const closeNFTDetailsAndOpenCrossChainNFTDrawer = async () => {
      hideNFTDetailsDrawer();
      showCrossChainNFTDrawer();
    };

    const closeSendNFTDrawerAndRefresh = async () => {
      hideSendNFTDrawer();
      setTimeout(function () {
        location.reload();
      }, 2000);
    };

    const closeSendTokenDrawerAndOpenSendToDrawer = async (item: any) => {
      address.value = item?.address;
      accountId.value = item?.accountId;
      hideSendTokenDrawer();
      // showSendToDrawer();
    };

    const closeSendTokenDrawerAndRefresh = async () => {
      hideSendTokenDrawer();
      setTimeout(function () {
        location.reload();
      }, 2000);
    };
    //wang
    const closeGgmSendTokenDrawerAndRefresh = async () => {
      hideGgmSendTokenDrawer();
      setTimeout(function () {
        location.reload();
      }, 2000);
    };
    const closeGgmCrossChainTokenDrawerAndRefresh = async () => {
      hideGgmCrossChainTokenDrawer();
      setTimeout(function () {
        location.reload();
      }, 2000);
    };

    const closeCrossChainTokenDrawerAndRefresh = async () => {
      hideCrossChainTokenDrawer();
      setTimeout(function () {
        location.reload();
      }, 2000);
    };

    const closeCrossChainNFTDrawerAndRefresh = async () => {
      hideCrossChainTokenDrawer();
      setTimeout(function () {
        location.reload();
      }, 2000);
    };

    const store = accountStore();
    const { getAccountId } = storeToRefs(store);
    console.log(getAccountId, 162);
    const cardAccountId =
      getAccountId.value == undefined || getAccountId.value == ""
        ? ref(-1)
        : ref(Number(getAccountId.value));

    return {
      childClick,
      activeKey: ref("1"),
      NFT_Type,

      openNFTDetailsDrawer,
      visibleSendNFTDrawer,
      showSendNFTDrawer,
      hideSendNFTDrawer,

      visibleNFTDetailsDrawer,
      showNFTDetailsDrawer,
      hideNFTDetailsDrawer,
      closeNFTDetailsAndOpenSendNFTDrawer,
      closeSendToDrawerAndOpenSendDrawer,
      sendType,
      assetType,
      address,
      accountId,
      item,
      user,

      // Send To NFT
      closeSendNFTDrawerAndRefresh,

      // Send To Token
      visibleSendTokenDrawer,
      showSendTokenDrawer,
      hideSendTokenDrawer,
      closeSendTokenDrawerAndOpenSendToDrawer,
      closeSendTokenDrawerAndRefresh,
      cardAccountId,
      //Ggm wang
      visibleGgmSendTokenDrawer,
      showGgmSendTokenDrawer,
      hideGgmSendTokenDrawer,
      closeGgmSendTokenDrawerAndRefresh,

      visibleGgmCrossChainTokenDrawer,
      showGgmCrossChainTokenDrawer,
      hideGgmCrossChainTokenDrawer,
      closeGgmCrossChainTokenDrawerAndRefresh,



      // CrossChain NFT
      visibleCrossChainNFTDrawer,
      showCrossChainNFTDrawer,
      hideCrossChainNFTDrawer,
      closeNFTDetailsAndOpenCrossChainNFTDrawer,
      closeCrossChainNFTDrawerAndRefresh,

      // CrossChain Token
      visibleCrossChainTokenDrawer,
      showCrossChainTokenDrawer,
      hideCrossChainTokenDrawer,
      closeCrossChainTokenDrawerAndRefresh,
    };
  },
});
