
import { defineComponent, ref } from "vue";

import Assets from "@/components/card/assets.vue";
import Account from "@/components/card/account.vue";
import NoData from "@/components/common/noData.vue";

import { Transaction_Type } from "@/constants/index";

import { useRouter } from "vue-router";

import { queryAccountActions } from "@/api";


export default defineComponent({
  components: {
    Assets,
    Account,
    NoData,
  },
  setup() {
    const accountPage = ref(1);

    let urlMapping = new Map();
    let nameMapping = new Map();

    const accountActions = ref();
    const getAccountActions = async (page: number) => {
      queryAccountActions(page).then((res) => {
        accountActions.value = res?.data?.account?.accountActions;
        busy.value = false
      });
    };
    getAccountActions(accountPage.value);

    const activeKey = ref("2");

    const busy = ref(false)

    const loadMore = () => {
      busy.value = true;
      getAccountActions(accountPage.value++);
    };

    const router = useRouter();
    const changeTabs = () => {
      router.push({
        name: "activity",
      });
    };

    return {
      Transaction_Type,
      activeKey,
      accountActions,
      urlMapping,
      nameMapping,
      busy,
      changeTabs,
      loadMore,
    };
  },
});
