
import { useTransactionStore } from "@/wallet/store/transaction";
import {defineComponent, inject, PropType, toRefs} from "vue";
import { openInExplorer } from "../utils";
import { BroadcastResult } from "@/wallet/types/ICommon";
import { useEthers } from "vue3-dapp-boot";

export default defineComponent({
  props: {
    description: {
      type: String,
      required: false,
    },
    result: {
      type: Object as PropType<BroadcastResult>,
      required: true,
    },
  },
  setup(props, context) {
    const { result } = toRefs(props);

    const transactionStore = useTransactionStore();

    const onConfirmed = () => {
      context.emit("close");
      if (result.value.status === 1) {
        if (transactionStore.onTransactionClosed) {
          transactionStore.onTransactionClosed();
        } else {
          transactionStore.onReload && transactionStore.onReload();
        }
      } else {
        transactionStore.onReload && transactionStore.onReload();
      }
    };

    return {
      openScan() {
        openInExplorer(props.result.transactionHash);
      },
      onConfirmed,
    };
  },
});
