
import { defineComponent, ref, watchEffect } from "vue";
import TabbarLayout from "@/layouts/module/Tabbar.vue";
import { useRouter, useRoute } from "vue-router";

export default defineComponent({
  name: "Footer",
  components: {
    TabbarLayout,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const accountId = ref("");
    const back = () => {
      router.push({
        name: "activity",
      });
    };

    watchEffect(async () => {
      if (route.params.id == undefined) {
        return;
      }
      accountId.value = String(route.params.id);
    });

    return {
      route,
      accountId,
      back,
    };
  },
});
