
import { defineComponent, ref, watchEffect } from "vue";
import TabbarLayout from "@/layouts/module/Tabbar.vue";
import WalletConnector from "@/wallet/components/WalletConnector.vue";

import { useRouter, useRoute } from "vue-router";


export default defineComponent({
  name: "Header",
  components: {
    WalletConnector,
    TabbarLayout,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const accountId = ref("");

    const back = () => {
      router.push({
        name: "activity",
      });
    };


    watchEffect(async () => {
      if (route.params.id == undefined) {
        return;
      }
      accountId.value = String(route.params.id);
    });

    return {
      route,
      accountId,
      back,
    };
  },
});
