
import { defineComponent, onMounted, ref,watch  } from "vue";
import Address from "@/components/card/address.vue";
import World from "@/components/card/world.vue";

import { queryAccountInfo } from "@/api";

export default defineComponent({
  components: {
    Address,
    World,
  },
  setup() {
    console.log(66)
    const accountInfo = ref();
    queryAccountInfo().then((res) => {
      accountInfo.value = res.data;
      // console.log(accountInfo.value);
    });

    return {
      accountInfo,
    };
  },
});
