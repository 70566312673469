
import { defineComponent, ref } from "vue";
import { Assets_Type, Transaction_Type } from "@/constants/index";

export default defineComponent({
  props: {
    isEnabled: {
      type: Boolean,
      required: true,
    },
    url: {
      type: String,
      required: false,
    },
  },
  setup() {
    const loading = ref(false);

    const handleClick = () => {
      loading.value = !loading.value;
    };

    return {
      loading,
      handleClick,
      Assets_Type,
      Transaction_Type,
    };
  },
});
