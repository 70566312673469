
import { defineComponent, ref, watch } from "vue";
import { getPlacement, getTimestamp, getDeadline } from "@/utils";
import {
  ASSETS_LIST,
  NFT_Type,
  Symbol_Type,
  User_Type,
  IOptionUser,
  DEFAULT_IMG,
  GET_ASSET721_BODY,
  ASSET721_TRANSFER,
} from "@/constants";
import { queryNFTs } from "@/api";

import User from "@/components/card/user.vue";

import { useEthers } from "vue3-dapp-boot";
import { apiGetBody, apiOperator } from "@/api/operator";

import { accountStore } from "@/store";
import { storeToRefs } from "pinia";
import WalletConnect from "@walletconnect/client";

import { CaretDownFilled } from "@ant-design/icons-vue";
//wang
import { Modal } from 'ant-design-vue';
import {h } from 'vue';
import axios from "axios";
let timeout: any;
let currentValue = "";
const cdapi =process.env.VUE_APP_CD_API?.toLocaleLowerCase() as string;
function fetch(accountIdValue: string, callback: any) {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  currentValue = accountIdValue;

  function fake() {
    axios
      .post(cdapi+'getuserinfo', {
        uid: accountIdValue,
      })
      .then((d) => {
        if (currentValue === accountIdValue) {
          const result = ref();
          result.value = [d.data?.message];
          const data: any[] = [];
          result.value.forEach((r: any) => {
            console.log(r,77)
            data.push({
              value: r.account,
              label: r.account+'('+r.uuid+')',
            });
          });
          callback(data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  timeout = setTimeout(fake, 300);
}

export default defineComponent({
  name: "SendNFT",
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  components: {
    User,
    CaretDownFilled,
  },
  setup(props: any, context: { emit: (arg0: string, arg1: any) => void }) {
    const level1Value = ref(
      props.item.asset.symbol === Symbol_Type.MOGA
        ? NFT_Type.MOGA
        : NFT_Type.EQPT
    );

    const level2Value = ref(props.item.itemId);
    const accountIdValue = ref();
    const remarkValue = ref();
    const itemImageUrl = ref(props.item.logoUrl);

    const checked = ref(false);
    const placement = ref(String(getPlacement()));

    const list = ref();
    const loading = ref(false);
    let logoUrlMapping = new Map();

    const getList = () => {
      loading.value = true;
      queryNFTs(level1Value.value === NFT_Type.MOGA ? 0 : 1).then((res) => {
        list.value = res.data.nfts;
        list.value.forEach((element) => {
          element.metadatas.forEach((data) => {
            if (data.key == "logo_url") {
              logoUrlMapping.set(element.itemId, data.value);
            }
          });
        });
        loading.value = false;
      });
    };
    getList();

    const { address } = useEthers();

    const store = accountStore();
    const { getAccountId, getContacts } = storeToRefs(store);

    const isError = ref(false)
    const isErrorMessage = ref("")

    const deadline = getDeadline();

    const contactsList = getContacts;
    let retObj: IOptionUser = {
      label: "",
      value: ""
    };
    //wang
    const data1 = ref<any[]>([]);
    const myhandleSearch = (val: string) => {
      fetch(val, (d: any[]) => (data1.value = d));
    };
    const myhandleChange = (val: string) => {
      accountIdValue.value = val;

      fetch(val, (d: any[]) => (data1.value = d));
    };

    if (contactsList.value.length == 0) {
      contactsList.value.push(retObj)
    } else if (contactsList.value.length == 2) {
      contactsList.value.splice(0, 1)
    }

    const handleSendClick = () => {

      if (accountIdValue.value == undefined) {
        isErrorMessage.value = "accountId is not null"
        isError.value = true
        return
      }

      //wang
      Modal.info({
        title: 'This is a notification message',
        content: h('div', {}, [
          h('p', 'Please confirm the operation on the wallet...'),
        ]),
        onOk() {
          console.log('ok');
        },
      });

      // return
      const data = ref(GET_ASSET721_BODY);
      data.value.id = getTimestamp();
      const toId = String(accountIdValue.value).split("-")[0]
      const params = [
        {
          sender: address.value,
          contractAddr: props.item.asset.id,
          fromId: Number(getAccountId.value),
          toId: Number(toId),
          tokenId: String(props.item.itemId),
          deadline: deadline,
        },
      ];
      data.value.params = params;
      apiGetBody(data.value).then((res) => {
        eth_signTypedData(res);
      });
    };

    const eth_signTypedData = async (typedData: any) => {

      console.log(typedData.result.typedData)

      const connector = new WalletConnect({
        bridge: "https://bridge.walletconnect.org", // Required
      });

      const signTypedData = {
        jsonrpc: typedData.jsonrpc,
        id: typedData.id,
        types: typedData.result.typedData.types,
        primaryType: typedData.result.typedData.primaryType,
        domain: typedData.result.typedData.domain,
        message: typedData.result.typedData.message,
      };

      const msgParams = [address.value, JSON.stringify(signTypedData)];

      // console.log(msgParams)

      connector
        .signTypedData(msgParams)
        .then((signature) => {
          const data = ref(ASSET721_TRANSFER);
          data.value.id = getTimestamp();

          const ethSigUtil = require("@metamask/eth-sig-util");

          const hash = ethSigUtil.TypedDataUtils.eip712Hash(
            signTypedData,
            ethSigUtil.SignTypedDataVersion.V4
          );

          const toId = String(accountIdValue.value).split("-")[0]
          const params = [
            {
              sender: String(address.value),
              contractAddr: String(props.item.asset.id),
              fromId: Number(getAccountId.value),
              toId: Number(toId),
              tokenId: String(props.item.itemId),
              hash: "0x" + hash.toString("hex"),
              signature: String(signature),
              deadline: deadline,
            },
          ];
          data.value.params = params;
          apiOperator(data.value).then((r) => {
            if (r?.error?.code==-32000) {
              Modal.info({
        title: 'This is a notification message',
        content: h('div', {}, [
          h('p', r?.error?.message),
        ]),
        onOk() {
          console.log('ok');
        },
      });
            }
            console.log(r,777)
            if (remarkValue.value != undefined) {

              let retObj: IOptionUser = {
                label: toId + "-" + remarkValue.value,
                value: toId + "-" + remarkValue.value,
              };

              if (contactsList.value.indexOf(retObj) == -1) {
                contactsList.value.push(retObj);
              }

              let newArr = ref([] as Array<IOptionUser>)
              const obj: { [key: string]: boolean; } = {};
              newArr.value = contactsList.value.reduce<IOptionUser[]>((item, next) => {
                if (!obj[next.value]) {
                  item.push(next);
                  obj[next.value] = true;
                }
                return item;
              }, []);

              store.$patch({
                contacts: newArr.value,
              });

            }

            context.emit("eventRefresh", "");
          });
        })
        .catch((error) => {
          console.error(error);
        });
    };

    const handleChange = () => {
      level2Value.value = "";
      itemImageUrl.value = undefined;
      getList();
    };

    const filterOption = (input: string, option: any) => {
      accountIdValue.value = input
      return option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };

    const getItemUrl = () => {
      itemImageUrl.value = logoUrlMapping.get(level2Value.value);
    };

    watch(props, ({ item }) => {
      level1Value.value =
        item.asset.symbol === Symbol_Type.MOGA ? NFT_Type.MOGA : NFT_Type.EQPT;
      level2Value.value = item.itemId;
      itemImageUrl.value = item.logoUrl;
      accountIdValue.value = "";
      remarkValue.value = ""
      getList();
    });

    const focus = () => {
      isError.value = false
    }

    return {
      level1Value,
      level2Value,
      accountIdValue,
      remarkValue,
      checked,
      placement,
      list,
      loading,
      itemImageUrl,
      ASSETS_LIST,
      User_Type,
      DEFAULT_IMG,
      logoUrlMapping,
      contactsList,
      isError,
      isErrorMessage,
      handleSendClick,
      handleChange,
      getItemUrl,
      filterOption,
      focus,
      //wang
      myhandleSearch,
      myhandleChange,
      data1,
    };
  },
});
