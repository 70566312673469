
import { defineComponent, ref } from "vue";
import { Assets_Type, Transaction_Type } from "@/constants/index";
import { shortenAddress } from "@/utils";
import { useClipboard } from "@vueuse/core";

var jazzicon = require("@metamask/jazzicon");

export default defineComponent({
  props: {
    address: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const loading = ref(false);

    const handleClick = () => {
      loading.value = !loading.value;
    };

    const { copy } = useClipboard();
    const iconNode = jazzicon(56, parseInt(props.address.slice(2, 10), 16));
    const avatar = document.createElement("div").appendChild(iconNode);

    return {
      shortenAddress,
      loading,
      handleClick,
      Assets_Type,
      Transaction_Type,
      copy,
      avatar,
    };
  },
});
