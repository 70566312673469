export enum Transaction_Type {
  Transfer = "AssetTransfer",
}

export enum Assets_Type {
  Token = 0,
  NFT = 1,
}

export enum NFT_Type {
  MOGA = "Moga",
  EQPT = "Equip",
}

export enum Symbol_Type {
  MOGA = "MOGA",
}

export enum User_Type {
  Select = "Select",
  Close = "Close",
}

// 判断类型 用于Acount页面
export const METAVERSE_TYPE = "0x375e556559b149ea7254c9616d624e6b9f3b66b3";

export const SYMBOL_GGM = "GCOIN";

export const PAGE_SIZE = 10;

export const GONSCAN_URL = "https://www.gonscan.com/tx/";
export const GONSCAN_ACTIONS_URL = "https://www.gonscan.com/actions/";
export const GONSCAN_TX_URL = "https://www.gonscan.com/tx/";
export const GONSCAN_ADDRESS_URL = "https://www.gonscan.com/address/";

export const MANAGER = process.env.VUE_APP_GON_MANAGER_CONTRACT?.toLocaleLowerCase() as string
export const TOKEN = process.env.VUE_APP_GCOIN_CONTRACT?.toLocaleLowerCase() as string
//wang
export const GGMTOKEN = process.env.VUE_APP_GGM_CONTRACT?.toLocaleLowerCase() as string

export const MARKETPLACE_MOGA_URL =
  "https://marketplace.playmonstergalaxy.com/assets/moga";
export const MARKETPLACE_EQUIPMENT_URL =
  "https://marketplace.playmonstergalaxy.com/assets/equipment";

import { ref } from "vue";

export const ASSETS_LIST = ref([
  {
    value: "Moga",
    label: "Moga",
  },
  {
    value: "Equip",
    label: "Equip",
  },
]);

export const CHAIN_LIST = ref([
  {
    value: "Moga",
    label: "Moga",
  },
  {
    value: "Equip",
    label: "Equip",
  },
]);

export interface IUser {
  accountId: string;
  address: string;
  type: number;
}

export interface IUsers {
  accountId: string;
  address: string;
}

export interface IOptionUser {
  label: string;
  value: string;
}

export const DEFAULT_IMG = "/assets/default.png";

export const GET_ASSET721_BODY = {
  jsonrpc: "2.0",
  method: "operator_getAsset721TransferFromBWOBody",
  params: [
    {
      sender: "",
      contractAddr: "",
      fromId: 0,
      toId: 0,
      tokenId: "0",
      deadline: 0,
    },
  ],
  id: 1,
};

export const GET_ASSET20_BODY = {
  jsonrpc: "2.0",
  method: "operator_getAsset20TransferFromBWOBody",
  params: [
    {
      sender: "",
      contractAddr: "",
      fromId: 0,
      toId: 0,
      amount: "0",
      deadline: 0,
    },
  ],
  id: 1,
};

export const ASSET721_TRANSFER = {
  jsonrpc: "2.0",
  method: "operator_asset721TransferFromBWO",
  params: [
    {
      sender: "",
      contractAddr: "",
      fromId: 0,
      tokenId: "0",
      deadline: 0,
      hash: "",
      signature: "",
    },
  ],
  id: 1,
};

export const ASSET20_TRANSFER = {
  jsonrpc: "2.0",
  method: "operator_asset20TransferFromBWO",
  params: [
    {
      sender: "",
      contractAddr: "",
      fromId: 0,
      amount: "0",
      deadline: 0,
      hash: "",
      signature: "",
    },
  ],
  id: 1,
};

export const GET_BRIDGEINFO = {
  jsonrpc: "2.0",
  method: "bridge_getBridgeInfo",
  params: [],
  id: 1
}

export const GET_CROSSCHAIN_ERC20_TRANSFER_BODY = {
  jsonrpc: "2.0",
  method: "bridge_getCrossChainERC20TransferBWOBody",
  params: [
    {
      manager: "",
      token: "",
      sender: "",
      receiver: "",
      fromId: 0,
      toId: 0,
      amount: "0",
      disChainId: 0,
      deadline: 0
    }
  ],
  id: 1
};

export const ERC20_CROSSCHAIN_TRANSFER = {
  jsonrpc: "2.0",
  method: "bridge_crossChainERC20TransferBWO",
  params: [
    {
      manager: "",
      token: "",
      sender: "",
      receiver: "",
      fromId: 0,
      toId: 0,
      amount: "0",
      disChainId: 0,
      deadline: 0,
      hash: "",
      signature: ""
    },
  ],
  id: 1,
};

export const GET_CROSSCHAIN_ERC721_TRANSFER_BODY = {
  jsonrpc: "2.0",
  method: "bridge_getCrossChainERC721TransferBWOBody",
  params: [
    {
      manager: "",
      nft: "",
      sender: "",
      receiver: "",
      fromId: 0,
      toId: 0,
      tokenId: "0",
      disChainId: 0,
      deadline: 0
    }
  ],
  id: 1
};

export const ERC721_CROSSCHAIN_TRANSFER = {
  jsonrpc: "2.0",
  method: "bridge_crossChainERC721TransferBWO",
  params: [
    {
      manager: "",
      nft: "",
      sender: "",
      receiver: "",
      fromId: 0,
      toId: 0,
      tokenId: "0",
      disChainId: 0,
      deadline: 0,
      hash: "",
      signature: ""
    },
  ],
  id: 1,
};
export const getCrossChainTxHistory = {
  jsonrpc: "2.0",
  method: "bridge_getCrossChainTxHistory",
  params: [
    {
      page:1,
      pageSize:20,
      account: "",
      asset: ""
    },
  ],
  id: 1,
};  