import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, resolveDirective as _resolveDirective } from "vue"

const _hoisted_1 = {
  class: "activit mb-24 -mt-16",
  "infinite-scroll-disabled": "busy",
  "infinite-scroll-distance": "10"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_Account = _resolveComponent("Account", true)!
  const _component_NoData = _resolveComponent("NoData")!
  const _component_a_tabs = _resolveComponent("a-tabs")!
  const _directive_infinite_scroll = _resolveDirective("infinite-scroll")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_tabs, {
      type: "card",
      activeKey: _ctx.activeKey,
      "onUpdate:activeKey": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeKey) = $event)),
      onChange: _ctx.changeTabs
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_tab_pane, {
          key: "1",
          tab: "Asset Activity",
          class: "bg-component border-b rounded-b-xl rounded-tr-xl p-3 bg-white text-black mb-24"
        }),
        _createVNode(_component_a_tab_pane, {
          key: "2",
          tab: "Account Activity",
          class: "bg-component border-b rounded-b-xl rounded-tr-xl p-3 bg-white text-black"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.accountActions, (item) => {
              return _withDirectives((_openBlock(), _createElementBlock("div", {
                key: item.id
              }, [
                _createVNode(_component_Account, {
                  address: item?.sender,
                  title: item?.actionType,
                  day: item?.action?.timestamp,
                  hash: item?.action?.transaction?.id,
                  param2: item?.param2,
                  logIndex: item?.action?.logIndex,
                  type: item?.action?.contract
                }, null, 8, ["address", "title", "day", "hash", "param2", "logIndex", "type"])
              ])), [
                [_vShow, _ctx.accountActions?.length > 0]
              ])
            }), 128)),
            _withDirectives(_createVNode(_component_NoData, null, null, 512), [
              [_vShow, _ctx.accountActions?.length == 0]
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["activeKey", "onChange"])
  ])), [
    [_directive_infinite_scroll, _ctx.loadMore]
  ])
}