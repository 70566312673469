import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "layout-footer flex-col justify-center items-center space-y-4 mt-4 lg:flex-row lg:items-start lg:space-x-28 lg:mt-4 lg:py-8" }
const _hoisted_2 = {
  class: "w-full",
  align: "center"
}
const _hoisted_3 = {
  key: 1,
  class: "wallet-button rounded-3xl bg-white px-2 py-1 w-80 black-title cursor-pointer mb-2"
}
const _hoisted_4 = { class: "accountId ml-5 rounded-full h-full text-base px-3 py-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TabbarLayout = _resolveComponent("TabbarLayout")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.route.meta.top === true)
        ? (_openBlock(), _createBlock(_component_TabbarLayout, { key: 0 }))
        : (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("span", {
              class: "cursor-pointer",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.back && _ctx.back(...args)))
            }, "<Back"),
            _createElementVNode("span", _hoisted_4, "accountId:" + _toDisplayString(_ctx.accountId), 1)
          ]))
    ])
  ]))
}