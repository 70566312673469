
import { useTransactionStore } from "@/wallet/store/transaction";
import { defineComponent, inject, PropType, toRefs } from "vue";
import { openInExplorer } from "../utils";
import { BroadcastResult } from "@/wallet/types/ICommon";
import { useEthers } from "vue3-dapp-boot";
import { isMobile } from "@/wallet/utils";

export default defineComponent({
  props: {
    description: {
      type: String,
      required: false,
    },
    result: {
      type: Object as PropType<BroadcastResult>,
      required: true,
    },
  },
  setup(props, context) {
    const { result } = toRefs(props);

    const transactionStore = useTransactionStore();

    const { chainId } = useEthers();

    // const reload = inject("reload");

    const onConfirmed = () => {
      context.emit("close");
      if (result.value.status === 1) {
        transactionStore.onTransactionClosed &&
          transactionStore.onTransactionClosed();
      } else {
        // console.log("sadfasdfadfasd")
        // if (typeof reload === "function") {
        //   reload();
        // }
      }
    };

    return {
      isMobile,
      openScan() {
        openInExplorer(props.result.transactionHash || props.result.hash);
      },
      onConfirmed,
    };
  },
});
