import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, resolveDirective as _resolveDirective } from "vue"

const _hoisted_1 = {
  class: "activity -mt-16",
  "infinite-scroll-disabled": "busy",
  "infinite-scroll-distance": "10"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Assets = _resolveComponent("Assets")!
  const _component_NoData = _resolveComponent("NoData")!
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_a_tabs = _resolveComponent("a-tabs")!
  const _directive_infinite_scroll = _resolveDirective("infinite-scroll")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_tabs, {
      type: "card",
      activeKey: _ctx.activeKey,
      "onUpdate:activeKey": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeKey) = $event)),
      onChange: _ctx.changeTabs
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_tab_pane, {
          key: "1",
          tab: "Asset Activity",
          class: "bg-component border-b rounded-b-xl rounded-tr-xl p-3 bg-white text-black mb-24"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.assetActions, (item) => {
              return _withDirectives((_openBlock(), _createElementBlock("div", {
                key: item.id
              }, [
                _createVNode(_component_Assets, {
                  "logo-url": 
            _ctx.logoUrlMapping.get(item.item.id) != undefined
              ? _ctx.logoUrlMapping.get(item.item.id)
              : ''
          ,
                  title: item.asset.symbol,
                  transactionType: _ctx.Transaction_Type.Transfer,
                  day: item?.action?.timestamp,
                  "is-in": item?.param3,
                  "is-b-w-o": item?.isBWO,
                  hash: item?.action?.transaction?.id,
                  param2: item?.param2,
                  logIndex: item?.action?.logIndex,
                  param1: item?.param1,
                  name: 
              _ctx.nameMapping.get(item.item.id) != undefined
                ? _ctx.nameMapping.get(item.item.id)
                : ''
            
                }, null, 8, ["logo-url", "title", "transactionType", "day", "is-in", "is-b-w-o", "hash", "param2", "logIndex", "param1", "name"])
              ])), [
                [_vShow, _ctx.assetActions?.length > 0]
              ])
            }), 128)),
            _withDirectives(_createVNode(_component_NoData, null, null, 512), [
              [_vShow, _ctx.assetActions?.length == 0]
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_a_tab_pane, {
          key: "2",
          tab: "Account Activity",
          class: "bg-component border-b rounded-b-xl rounded-tr-xl p-3 bg-white text-black"
        })
      ]),
      _: 1
    }, 8, ["activeKey", "onChange"])
  ])), [
    [_directive_infinite_scroll, _ctx.loadMore]
  ])
}