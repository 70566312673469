
import { defineComponent, ref } from "vue";
import { queryNFTsByAccountId } from "@/api";
import { Assets_Type, NFT_Type } from "@/constants";
import { MARKETPLACE_EQUIPMENT_URL, MARKETPLACE_MOGA_URL } from "@/constants";
import NoData from "@/components/common/noData.vue";

export default defineComponent({
  props: {
    type: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    accountId: {
      type: Number,
      required: true,
    },
  },
  components: {
    NoData,
  },
  setup(
    props: any,
    context: {
      emit: (arg0: string, arg1: any) => void;
    }
  ) {
    const loading = ref(true);

    const list = ref();
    let urlMapping = new Map();
    let logoUrlMapping = new Map();
    let nameMapping = new Map();

    if (props.type == NFT_Type.MOGA) {
      queryNFTsByAccountId(0, props.accountId).then((res) => {
        list.value = res.data.nfts;
        assist();
        loading.value = false;
      });
    } else if (props.type == NFT_Type.EQPT) {
      queryNFTsByAccountId(1, props.accountId).then((res) => {
        list.value = res.data.nfts;
        assist();
        loading.value = false;
      });
    }

    const handleClick = (item: any) => {
      item.name = nameMapping.get(item.itemId);
      item.imageUrl = urlMapping.get(item.itemId);
      item.logoUrl = logoUrlMapping.get(item.itemId);
      item.type = Assets_Type.NFT;
      context.emit("event", item);
    };

    return {
      loading,
      handleClick,
      list,
      urlMapping,
      logoUrlMapping,
      nameMapping,
      NFT_Type,
      MARKETPLACE_MOGA_URL,
      MARKETPLACE_EQUIPMENT_URL,
    };

    function assist() {
      list.value.forEach((element) => {
        element.metadatas.forEach((data) => {
          if (data.key == "image_url") {
            urlMapping.set(element.itemId, data.value);
          } else if (data.key == "name") {
            nameMapping.set(element.itemId, data.value);
          } else if (data.key == "logo_url") {
            logoUrlMapping.set(element.itemId, data.value)
          }
        });
      });
    }
  },
});
