import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col justify-end h-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    width: "auto",
    class: "modal-transaction-reject",
    centered: "",
    closable: false,
    maskClosable: false,
    visible: true,
    onCancel: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close'))),
    footer: null
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_a_button, {
          type: "default",
          size: !_ctx.isMobile ? 'large' : 'default',
          ghost: "",
          block: "",
          shape: "round",
          class: "wallet-button space-x-2 w-48 mx-auto",
          onClick: _ctx.onConfirmed
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Dismiss ")
          ]),
          _: 1
        }, 8, ["size", "onClick"])
      ])
    ]),
    _: 1
  }))
}