import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import WalletView from "../views/Wallet/Index.vue";
import WallectByAccountIdView from "../views/Wallet/Account.vue";
import ActivityView from "../views/Activity/Index.vue";
import AccountActityView from "../views/Activity/Account.vue";
import AccountView from "../views/Account/Index.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/wallet",
    meta: { title: "", top: true },
  },
  {
    path: "/wallet",
    name: "wallet",
    component: WalletView,
    meta: { title: "", top: true },
  },
  {
    path: "/wallet/:id",
    name: "accountWallet",
    component: WallectByAccountIdView,
    meta: { title: "", top: false },
  },
  {
    path: "/activity",
    name: "activity",
    component: ActivityView,
    meta: { title: "", top: true },
  },
  {
    path: "/activity",
    name: "accountActivity",
    component: AccountActityView,
    meta: { title: "", top: true },
  },
  {
    path: "/account",
    name: "account",
    component: AccountView,
    meta: { title: "", top: true },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
